// Page Components
import AboutSection from '../Components/AboutSection';

const AboutUs = () => {
	return (
		<div>
			<AboutSection />
		</div>
	);
};

export default AboutUs;
